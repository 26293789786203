<template>
  <div id="app">
    <el-row type="flex" justify="space-around" style="text-align: center">
      <el-col :xs="24" :lg="10">
        <el-card shadow="always" id="inputContent">
          <el-form :model="userInfo" ref="dynamicValidateForm" label-width="70px" :rules="rules">
            <el-upload
                class="avatar-uploader"
                action="api/file/saveToTemp"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-form-item label="名称" prop="usrName">
              <el-input v-model="userInfo.usrName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item prop="usrEmail" label="邮箱">
              <el-input v-model="userInfo.usrEmail" :disabled="true"></el-input>
            </el-form-item>
            <!--            <el-form-item prop="emailCode" label="验证码">-->
            <!--              <el-input v-model="userInfo.emailCode">-->
            <!--                <el-button slot="append" @click="sendEmailCode">{{this.timerTime}}</el-button>-->
            <!--              </el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="密码" prop="usrPwd">-->
            <!--              <el-input type="password" v-model="userInfo.usrPwd" autocomplete="off"></el-input>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="确认密码" prop="checkPass">-->
            <!--              <el-input type="password" v-model="userInfo.checkPass" autocomplete="off"></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item label="手机">
              <el-input v-model.number="userInfo.usrPhone"></el-input>
            </el-form-item>
            <el-form-item prop="usrQQ" label="QQ">
              <el-input v-model.number="userInfo.usrQQ"></el-input>
            </el-form-item>
            <el-form-item label="微信">
              <el-input v-model.number="userInfo.usrWechat"></el-input>
            </el-form-item>
            <el-row style="margin-top: 10px" type="flex" justify="center">
              <el-col :span="18">
                <el-button type="success" @click="updUserInfo('dynamicValidateForm')" round>
                  {{ $t('login.save') }}
                </el-button>
                <el-button type="info" @click="$router.push({path: '/register'})" round>
                  {{ $t('login.register') }}
                </el-button>
                <el-col :span="6">
                  <el-button @click="resetForm('dynamicValidateForm')">{{ $t('login.reset') }}</el-button>
                </el-col>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import users from "@/request/users";

export default {
  name: "userInfo",
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.userInfo.checkPass !== '') {
          this.$refs.dynamicValidateForm.validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userInfo.usrPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    const validateQQ = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (!Number.isFinite(Number(value))) {
        callback(new Error('QQ必须为数字值!'));
      } else {
        callback();
      }
    };
    return {
      imageUrl: '',
      ask: new users(),
      lang: 'English',
      timer: null,
      timerTime: '发送',
      userInfo: {
        usrName: '',
        usrPwd: '',
        checkPass: '',
        usrEmail: '',
        usrQQ: '',
        usrWechat: '',
        usrPhone: '',
        emailCode: '',
        usrIcon: ''
      },
      rules: {
        usrName: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur'}
        ],
        // emailCode: [
        //   {required: true, message: '请输入邮箱验证码', trigger: 'blur'},
        // ],
        // usrPwd: [
        //   {required: true, message: '请输入密码', trigger: 'blur'},
        //   {min: 5, max: 8, message: '长度在 5 到 8 个字符', trigger: 'blur'},
        //   {validator: validatePass, trigger: 'blur'}
        // ],
        // checkPass: [
        //   {validator: validatePass2, trigger: 'blur'}
        // ],
        // usrEmail: [
        //   {required: true, message: '请输入邮箱地址', trigger: 'blur'},
        //   {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        // ],
        usrQQ: [
          {validator: validateQQ, trigger: 'blur'}
        ]
      },
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.userInfo.usrIcon = res.msg
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG, PNG 格式!');
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt10M;
    },

    updUserInfo(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ask.updUser(this.userInfo).then(res => {
            this.$message({
              showClose: true,
              duration: 0,
              message: '修改成功',
              type: 'success'
            });
            this.$storageHandler.setSessionStorage("loginUser", res.msg);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /**
     * 发送邮箱验证码
     */
    sendEmailCode() {
      this.timerTime = 60;
      this.timer = setInterval(() => {
        this.timerTime--;
        if (this.timerTime <= 0) {
          clearInterval(this.timer);
          this.timerTime = "发送";
        }
      }, 1000)
      const this_ = this;
      let a = this.$refs.dynamicValidateForm.validateField('usrEmail', resule => {
        if (!resule) {
          this.ask.sendEmailCode({'usrEmail': this_.userInfo.usrEmail});
        }
      })
    }
  },
  created() {
    let data = sessionStorage.getItem('loginUser');
    const userInfo = JSON.parse(data);
    if (userInfo !== null) {
      this.userInfo.usrName = userInfo.lUsrName
      this.userInfo.usrEmail = userInfo.lUsrEmail
      this.userInfo.usrQQ = userInfo.lUsrQQ
      this.userInfo.usrWechat = userInfo.lUsrWechat
      this.userInfo.usrPhone = userInfo.lUsrPhone
      this.imageUrl = '/api/resources/user/' + userInfo.lUsrId + '/' + userInfo.lUsrIcon
    } else {
      this.$router.push({path: '/login'})
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 150px;
  height: 150px;
  display: block;
}

</style>
